import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Lightbox from 'react-image-lightbox';

const SideBySideImages = ({src1, alt1, src2, alt2}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const { allImageSharp } = useStaticQuery(graphql`
        query SideBySideImages {
            allImageSharp {
              edges {
                node {
                  id
                  fluid(maxWidth: 1920) {
                    src
                    srcSet
                    base64
                    aspectRatio
                    originalImg
                    originalName
                    sizes        
                  }
                }
              }
            }
        }
    `);

  const image1 = allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === src1
  );

  const image2 = allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === src2
  );

  const getOtherSrc = () => {
    if (currentImage === image1?.node.fluid.src) {
      return image2?.node.fluid.src;
    } else {
      return image1?.node.fluid.src;
    }
  };

  const setOtherSrc = () => {
    if (currentImage === image1?.node.fluid.src) {
      setCurrentImage(image2?.node.fluid.src);
    } else {
      setCurrentImage(image1?.node.fluid.src);
    }
  }

  return image1 && image2 ? (
    <div>
      <div className={"flex flex-row w-full p-5"}>
        <div onClick={() => { setModalOpen(true); setCurrentImage(image1?.node.fluid.src);}} className={"cursor-pointer mr-1"}>
          <img loading="lazy" src={image1?.node.fluid.src} alt={alt1} />
        </div>
        <div onClick={() => { setModalOpen(true); setCurrentImage(image2?.node.fluid.src);}} className={"cursor-pointer ml-1"}>
          <img loading="lazy" src={image2?.node.fluid.src} alt={alt2} />
        </div>
      </div>
      {modalOpen && <div className={"z-50 w-full h-full"}>
        <Lightbox
          animationDisabled={true}
          enableZoom={false}
          mainSrc={currentImage}
          nextSrc={getOtherSrc()}
          prevSrc={getOtherSrc()}
          onCloseRequest={() => setModalOpen(false)}
          onMovePrevRequest={setOtherSrc}
          onMoveNextRequest={setOtherSrc}
        />
      </div>}
    </div>
  ) : null;
};

export default SideBySideImages;
