import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Lightbox from 'react-image-lightbox';

const LightboxImage = ({src, alt, maxWidth, useBorder, className}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { allImageSharp } = useStaticQuery(graphql`
        query AllImages {
            allImageSharp {
              edges {
                node {
                  id
                  fluid(maxWidth: 1920) {
                    src
                    srcSet
                    base64
                    aspectRatio
                    originalImg
                    originalName
                    sizes        
                  }
                }
              }
            }
        }
    `);

  const image = allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === src
  );
  if (!image) {
    return null;
  }

  return (
    <div className={'p-5 ' + (maxWidth ? 'flex justify-center' : '') + (className ? ' ' + className : '')}>
      <div onClick={() => setModalOpen(true)} className={"cursor-pointer"}>
        <img loading="lazy"  src={image?.node.fluid.src} alt={alt} style={maxWidth && !modalOpen ? {maxWidth: maxWidth, width: '100%'} : {}} className={(useBorder ? 'shadow-2xl' : '')} />
      </div>
      {modalOpen && <div className={"z-50 w-full h-full"}>
        <Lightbox
          animationDisabled={true}
          enableZoom={false}
          mainSrc={image?.node.fluid.src}
          onCloseRequest={() => setModalOpen(false)}
        />
      </div>}
    </div>
  );
};

export default LightboxImage;
