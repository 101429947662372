import React, {useEffect, useState} from 'react';
import { Cookies } from 'react-cookie-consent';

const RedirectToRegister = (props) => {
  const isBrowser = typeof window !== "undefined";

  const [width, setWidth] = useState(isBrowser? window.innerWidth : 0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const onStartFreeTrial = () => {
    if (isBrowser) {
      let elapseitId = Cookies.get("elapseit_id");
      if (window && window.gtag) {
        window.gtag('event', 'sign_up', {
          'method': 'PricingPlans'
        });
        if (!isMobile) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-815257767/7PAgCLebrs8DEKex34QD',
            'event_callback': () => {
            }
          });
        }
      }
      if (elapseitId) {
        window.open("https://app.elapseit.com/register?elapseitId=" + elapseitId + "&language=" + props.language, "_blank");
      } else {
        window.open("https://app.elapseit.com/register?language=" + props.language, "_blank");
      }
    }
  };

  return <a className={"cursor-pointer"} onClick={onStartFreeTrial}>{props.text}</a>
}

export default RedirectToRegister;
